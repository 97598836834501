<script setup>
import { ref } from 'vue'

const toggle = ref(false)
</script>
<template>
    <div @click="toggle = !toggle" class="hamburger-menu">
        <div></div>
    </div>
</template>
<style scoped>
.hamburger-menu {
    @apply flex flex-col gap-2 max-w-max absolute top-[1.1rem] right-2 z-50;
    &::after,
    &::before,
    div {
        content: '';
        transform-origin: left center;
        @apply w-8 h-0.5 bg-gray-400 rounded-full transition-all duration-300;
    }
}

.enabled {
    div {
        @apply w-0 bg-red-primary opacity-0;
    }
    &::before {
        @apply rotate-45 -translate-y-0.5 translate-x-1;
    }
    &::after {
        @apply -rotate-45 translate-x-1;
    }
}
</style>
