<script setup>
import { watch, ref, onMounted, onUnmounted } from 'vue'
import Hamburger from '../Hamburger.vue'
import { MainLogo } from '@/Components/Icons'
import {
    XMarkIcon,
    ChevronDownIcon,
    ChevronDoubleRightIcon,
    DocumentTextIcon,
    BuildingOffice2Icon,
    UserCircleIcon,
    PlusIcon
} from '@heroicons/vue/24/outline'
import Company from './NavComponents/Company.vue'
import Solution from './NavComponents/OurSolution.vue'

const scrolledNav = ref(null)
const mobile = ref(null)
const mobileNav = ref(null)
const windowWidth = ref(null)
const companyHover = ref(false)
const solutionHover = ref(false)
const mobileVendorActive = ref(false)
const mobileCustomerActive = ref(false)
const closeSub = ref(true)
const mobileNavClick = ref(false)

if (typeof window !== 'undefined') {
    const windowX = ref(window.innerWidth)
    const windowY = ref(window.innerHeight)
    const scrollPosition = ref(window.scrollY)
    const handleResize = () => {
        windowX.value = window.innerWidth
        windowY.value = window.innerHeight
    }
    function checkScreen () {
        windowWidth.value = window.innerWidth
        if (windowWidth.value <= 850) {
            mobile.value = true
            return
        }
        mobile.value = false
        mobileNav.value = false
    }
    function updateScroll () {
        scrollPosition.value = window.scrollY
        if (scrollPosition.value > 200) {
            scrolledNav.value = true
            return
        }
        scrolledNav.value = false
    }
    onMounted(() => {
        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', updateScroll)
    })
    onUnmounted(() => {
        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', updateScroll)
    })
    checkScreen()

    watch(windowX, () => {
        checkScreen()
    })
}

function toggleMobileNav () {
    mobileNav.value = !mobileNav.value
}

function closeMobileNav () {
    mobileNav.value = false
}

function closeAll () {
    companyHover.value = false
    solutionHover.value = false
    mobileVendorActive.value = false
    mobileCustomerActive.value = false
    closeSub.value = true
    mobileNav.value = false
}
</script>
<template>
    <header :class="{ 'scrolled-nav': scrolledNav }">
        <div class="fixed top-0 left-0 w-full h-screen z-40" v-if="!closeSub" @click="
            (closeSub = true),
            (companyHover = false),
            (solutionHover = false)
            "></div>
        <nav>
            <div class="branding z-50">
                <Link href="/" class="w-56">
                <MainLogo :hostname="$page.props.ziggy.url" @click="closeAll"/>
                </Link>
            </div>

            <ul v-show="!mobile && $page.props.ziggy.url.includes('m1financial')" class="navigation relative z-50 gap-6 lg:gap-0">
                <li class="md:mt-4 ml-4">
                    <span class="link cursor-pointer text-sm" @mouseover="
                        (companyHover = true),
                        (solutionHover = false),
                        (closeSub = false)
                        ">COMPANY</span>
                </li>
                <li class="md:mt-4">
                    <span class="link cursor-pointer text-sm" @mouseover="
                        (solutionHover = true),
                        (companyHover = false),
                        (closeSub = false)
                        " :class="{ 'icon-active': mobileNav }">OUR SOLUTIONS</span>
                </li>
                <li class="md:mt-4">
                    <Link class="link text-sm" :href="route('contact')" @mouseover="closeAll"><span>CONTACT US</span></Link>
                </li>
            </ul>
            <transition name="desktopSubMenu" v-show="!mobile && companyHover">
                <Company class="mt-2 px-4" @close="closeAll" />
            </transition>
            <Transition name="desktopSubMenu" v-show="!mobile && solutionHover">
                <Solution class="mt-2 px-4" @close="closeAll" />
            </Transition>
            <div class="ml-auto mr-0 flex items-stretch justify-stretch gap-2" v-if="!mobile">
                <div class="group bg-red-primary flex items-stretch justify-stretch rounded-md w-40">
                    <Link v-if="$page.props.auth.user" type="button" :href="route('application')"
                        class="text-white text-sm font-bold gap-2 overflow-hidden duration-300 grow flex items-center justify-center"
                        @click="closeAll">
                        <PlusIcon class="w-4 h-4 group-hover:scale-150 group-hover:rotate-90 duration-300"/>
                        <p>APPLICATION</p>
                    </Link>
                    <Link v-else type="button" :href="route('simple application', {type: 'customers'})"
                    class="text-white text-sm font-bold gap-2 overflow-hidden duration-300 grow flex items-center justify-center"
                        @click="closeAll">
                        <DocumentTextIcon
                        class="w-6 h-6 group-hover:scale-125 duration-300" />
                        <p>APPLY NOW</p>
                    </Link>
                </div>
                <div class="group bg-gray-100 flex items-stretch justify-stretch rounded-md hover:bg-gray-700 max-h-14">
                    <Link class="flex items-stretch justify-stretch group-hover:text-white" v-if="$page.props.auth.user" :href="route('portal')">
                        <div class="w-12 flex flex-col group-hover:flex-row items-center justify-center group-hover:w-36 ease-in-out duration-300 group-hover:gap-2">
                            <BuildingOffice2Icon class="w-8 block text-current duration-300" />
                            <p class="text-[0.5rem] group-hover:text-sm font-bold text-current duration-300">PORTAL</p>
                        </div>
                    </Link>
                    <Link class="flex items-stretch justify-stretch group-hover:text-white" :href="route('login')" v-else>
                        <div class="w-14 flex flex-col group-hover:flex-row items-center justify-center group-hover:w-28 ease-in-out duration-300 group-hover:gap-2">
                            <UserCircleIcon class="w-8 block text-current duration-300" />
                            <p class="text-[0.5rem] group-hover:text-sm font-bold text-current duration-300">LOGIN</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div class="z-50 mr-2 p-2  md:shadow-none absolute right-0 -top-1 rounded-md">
                <Hamburger @click="toggleMobileNav" v-show="mobile" :class="{ 'enabled': mobileNav }" />
            </div>

            <!-- // Mobile menu -->
            <transition name="desktopSubMenu">
                <div @click="closeAll" v-show="mobileNav"
                    class="fixed top-0 left-0 w-full h-full backdrop-blur-sm bg-white/10"></div>
            </transition>
            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav">
                    <li>
                        <div v-if="!$page.props.ziggy.url.includes('medonegroup')">
                            <div class="flex items-center p-4" @click="
                                (companyHover = !companyHover),
                                (solutionHover = false)
                                ">
                                <p class="cursor-pointer grow text-gray-primary">COMPANY</p>
                                <div class="w-1/4">
                                    <ChevronDownIcon class="w-6 cursor-pointer transition duration-500 ease-in-out mx-auto"
                                        :class="{ 'icon-active': companyHover }" />
                                </div>
                            </div>
                            <transition name="desktopSubMenu">
                                <ul v-show="companyHover" class="bg-gray-100 p-4">
                                    <li class="hover:bg-gray-100 duration-200 p-3" @click="closeAll">
                                        <Link :href="route('about us')">
                                        <p class="text-red-primary">ABOUT US</p>
                                        </Link>
                                    </li>
                                    <li class="hover:bg-gray-100 duration-200 p-3" @click="closeAll">
                                        <Link :href="route('leadership')">
                                        <p class="text-red-primary">LEADERSHIP</p>
                                        </Link>
                                    </li>
                                    <li class="hover:bg-gray-100 duration-200 p-3" @click="closeAll">
                                        <Link :href="route('blog')">
                                        <p class="text-red-primary">BLOG</p>
                                        </Link>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                    </li>
                    <li>
                        <div v-if="!$page.props.ziggy.url.includes('medonegroup')">
                            <div class="cursor-pointer flex items-center p-4" @click="
                                (solutionHover = !solutionHover),
                                (companyHover = false),
                                (mobileNavClick = false)
                                ">
                                <p class="grow text-gray-primary">OUR SOLUTIONS</p>
                                <div class="w-1/4">
                                    <ChevronDownIcon class="w-6 cursor-pointer transition duration-500 ease-in-out mx-auto"
                                        :class="{ 'icon-active': solutionHover }" />
                                </div>
                            </div>
                            <transition name="desktopSubMenu">
                                <ul v-show="solutionHover" class="bg-gray-100 px-8 py-10 space-y-8 relative">
                                    <li class="">
                                        <div @click="
                                            (mobileVendorActive = true),
                                            (mobileNavClick = true)
                                            " class="cursor-pointer">
                                            <div class="flex items-center">
                                                <p class="text-red-primary">
                                                    VENDOR FINANCING
                                                </p>
                                                <ChevronDownIcon class="cursor-pointer w-4 ml-4" />
                                            </div>
                                            <p class="text-sm font-normal">
                                                My company is looking for a
                                                financial partner to help me sell
                                                more equipment.
                                            </p>
                                        </div>
                                        <transition name="desktopSubMenu">
                                            <ul v-show="mobileVendorActive &&
                                                mobileNavClick
                                                "
                                                class="absolute top-0 left-0 bg-gradient-to-b from-gray-50 to-gray-100 w-full h-full p-8 flex flex-col justify-between z-50">
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('why m1', {
                                                        type: 'vendors',
                                                    })
                                                        ">WHY M1 FOR VENDORS</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('product offerings', { type: 'vendors' })">PRODUCT
                                                    OFFERINGS</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('industries', { type: 'vendors' })">INDUSTRIES</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('how it works', {
                                                        type: 'vendors',
                                                    })
                                                        ">
                                                    HOW IT WORKS
                                                    </Link>
                                                </li>
                                                <li class="text-red-primary" @click="mobileNav = false">
                                                    <Link :href="route(
                                                        'simple application',
                                                        { type: 'vendors' }
                                                    )
                                                        ">
                                                    APPLY NOW
                                                    </Link>
                                                </li>
                                                <li class="absolute top-0 right-0">
                                                    <div class="icon z-50">
                                                        <XMarkIcon @click="
                                                            mobileVendorActive = false
                                                            " class="w-6 h-6 mr-2 mt-2" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </transition>
                                    </li>
                                    <li class="border border-gray-300 opacity-50 relative z-0"></li>
                                    <li class="hover:bg-gray-100 duration-200">
                                        <div @click="
                                            (mobileCustomerActive = true),
                                            (mobileNavClick = true)
                                            " class="cursor-pointer">
                                            <div class="flex items-center">
                                                <p class="text-red-primary">
                                                    CUSTOMER FINANCING
                                                </p>
                                                <ChevronDownIcon class="cursor-pointer w-4 ml-4" />
                                            </div>
                                            <p class="text-sm font-normal">
                                                I am an end-user and I need help
                                                acquiring equipment or products for
                                                my business
                                            </p>
                                        </div>
                                        <transition name="desktopSubMenu">
                                            <ul v-show="mobileCustomerActive &&
                                                mobileNavClick
                                                "
                                                class="absolute top-0 left-0 bg-gradient-to-b from-gray-50 to-gray-100 w-full h-full p-8 flex flex-col justify-between">
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('why m1', {
                                                        type: 'customers',
                                                    })
                                                        ">WHY M1 FOR CUSTOMERS</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('product offerings', { type: 'customers' })">PRODUCT
                                                    OFFERINGS</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('industries', { type: 'customers' })">INDUSTRIES</Link>
                                                </li>
                                                <li class="text-gray-primary" @click="mobileNav = false">
                                                    <Link :href="route('how it works', {
                                                        type: 'customers',
                                                    })
                                                        ">
                                                    HOW IT WORKS
                                                    </Link>
                                                </li>
                                                <li class="text-red-primary" @click="mobileNav = false">
                                                    <Link :href="route(
                                                        'simple application',
                                                        { type: 'customers' }
                                                    )
                                                        ">
                                                    APPLY NOW
                                                    </Link>
                                                </li>
                                                <li class="absolute top-0 right-0">
                                                    <div class="icon z-50">
                                                        <XMarkIcon @click="
                                                            mobileCustomerActive = false
                                                            " class="w-6 h-6 mr-2 mt-2" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </transition>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                    </li>
                    <li class="p-4 text-gray-primary">
                        <a v-if="$page.props.ziggy.url.includes('medonegroup')" href="https://www.medonegroup.com/contactus">CONTACT US</a>
                        <Link v-else :href="route('contact')" @click="closeMobileNav">CONTACT US</Link>
                    </li>
                    <li class="p-4 text-red-primary border-b border-t border-red-primary">
                        <Link v-if="$page.props.auth.user" :href="route('portal')">MY PORTAL</Link>
                        <Link :href="route('login')" v-else>LOG IN</Link>
                    </li>
                    <li class="absolute bottom-0 left-0 w-full bg-red-primary text-white">
                        <Link type="button" @click="closeMobileNav" :href="$page.props.auth.user ? route('application') : route('simple application', {type: 'customers'})"
                            class="rounded-md font-bold w-full text-center flex items-center justify-center py-6">
                        <div class="w-1/5 relative">
                            <ChevronDoubleRightIcon
                                class="w-6 cursor-pointer transition duration-500 ease-in-out mx-auto" />
                        </div>
                        <p>{{ $page.props.auth.user ? 'SUBMIT APPLICATION' : 'APPLY NOW' }}</p>
                        <div class="w-1/5 relative">
                            <ChevronDoubleRightIcon
                                class="w-6 cursor-pointer transition duration-500 ease-in-out mx-auto" />
                        </div>
                        </Link>
                    </li>
                </ul>
            </transition>
        </nav>
    </header>
</template>

<style scoped>
header {
    @apply z-50 flex items-center w-full fixed transition duration-300 ease-in-out bg-white;

    nav {
        @apply flex flex-row px-2 xl:px-6 transition duration-500 ease-in-out my-2 mx-auto relative grow max-w-7xl;

        ul,
        .link {
            @apply font-medium list-none no-underline;

            li {
                @apply lg:p-2 lg:ml-10 min-w-fit;
            }
        }

        .link {
            @apply transition duration-300 ease-in-out pb-2 border-b border-transparent text-gray-600;

            &:hover {
                @apply text-red-primary;
            }
        }

        .branding {
            @apply flex items-center mr-3;

            img {
                @apply w-64 transition duration-500 ease-in-out;
            }
        }

        .navigation {
            @apply flex items-center shrink;
        }

        .icon {
            @apply flex absolute top-0 right-0 h-full;
        }

        .icon-active {
            @apply rotate-180;
        }

        .dropdown-nav {
            @apply flex flex-col fixed w-80 h-full bg-white top-0 right-0 pt-16;
            -webkit-box-shadow: -10px 16px 15px -10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: -10px 16px 15px -10px rgba(0, 0, 0, 0.2);
            box-shadow: -10px 60px 15px -10px rgba(0, 0, 0, 0.2);

            li {
                @apply lg:ml-4;
            }
        }

        .mobile-nav-enter-active,
        .mobile-nav-leave-active {
            @apply transition duration-300 ease-in-out;
        }

        .mobile-nav-enter-from,
        .mobile-nav-leave-to {
            @apply translate-x-96;
        }

        .mobile-nav-enter-to {
            @apply translate-x-0;
        }
    }
}

.scrolled-nav {
    @apply bg-white shadow-md;

    .link {
        @apply text-gray-700 drop-shadow-none;
    }
}

.desktopSubMenu-enter-active {
    transition: all 0.3s ease-out;
}

.desktopSubMenu-enter-from,
.desktopSubMenu-leave-to {
    transform: translateY(-30px);
    opacity: 0;
}</style>
