<script setup>
import { ref } from 'vue'
import BackDrop from '../BackDrop.vue'
import BigArrow from '../BigArrow.vue'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'

const vendor = ref(true)
const customer = ref(false)
</script>
<template>
    <BackDrop>
        <div
            class="w-full max-w-[61rem] mx-auto md:flex items-stretch justify-stretch md:rounded-2xl overflow-hidden shadow-lg relative z-50"
        >
            <div class="bg-gray-600 md:w-1/2 text-white flex flex-col items-stretch justify-stretch">
                <ul class="duration-300 flex flex-col h-full">
                    <li
                        class="group relative hover:bg-gray-700 px-6 py-8 h-1/2 flex items-center duration-100"
                        :class="vendor ? 'bg-gray-700' : ''"
                        @mouseover="(vendor = true), (customer = false)"
                    >
                        <div class="ml-6">
                            <h4 class="font-bold">VENDOR FINANCING</h4>
                            <p class="italic">
                                My company is looking for a financial partner to
                                help me sell more equipment.
                            </p>
                        </div>
                        <BigArrow
                            class="h-full absolute top-0 right-1 duration-100"
                            :class="vendor ? 'translate-x-[22px] opacity-100' : 'opacity-0'"
                        />
                    </li>
                    <li
                        class="group relative hover:bg-gray-700 px-6 py-8 h-1/2 flex items-center duration-100"
                        :class="customer ? 'bg-gray-700' : ''"
                        @mouseover="(customer = true), (vendor = false)"
                    >
                        <div class="ml-6">
                            <h4 class="font-bold">CUSTOMER FINANCING</h4>
                            <p class="italic">
                                I am an end-user and I need help acquiring
                                equipment or products for my business.
                            </p>
                        </div>
                        <BigArrow
                            class="h-full absolute top-0 right-1 opacity-0 duration-100"
                            :class="customer ? 'translate-x-[22px] opacity-100' : 'opacity-0'"
                        />
                    </li>
                </ul>
            </div>
            <!-- Vendor Menu -->
            <div
                class="md:w-1/2 bg-white px-10 py-6 md:flex flex-col"
                v-if="vendor"
            >
                <ul class="w-full font-medium">
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('why m1', {type: 'vendors'})"
                            @click="$emit('close', false)"
                        >
                            <p><span class="group-hover:text-white">WHY M1 FOR VENDORS</span></p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('product offerings', { type: 'vendors' })"
                            @click="$emit('close', false)"
                        >
                            <p><span class="group-hover:text-white">PRODUCT OFFERINGS</span></p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('industries', { type: 'vendors' })"
                            @click="$emit('close', false)"
                        >
                            <p><span class="group-hover:text-white">INDUSTRIES</span></p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('how it works', { type: 'vendors' })"
                            @click="$emit('close', false)"
                        >
                            <p><span class="group-hover:text-white">HOW IT WORKS FOR VENDORS</span></p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                </ul>
                <div class="p-4 w-full mt-6">
                    <Link
                        class="w-full block text-center text-white font-bold bg-red-primary px-4 py-2 rounded-lg"
                        :href="route('simple application', {type: 'vendors'})"
                        @click="$emit('close')"
                        >APPLY NOW</Link
                    >
                </div>
            </div>
            <!-- Customer Menu -->
            <div
                class="md:w-1/2 bg-white px-10 py-6 md:flex flex-col"
                v-if="customer"
            >
                <ul class="w-full font-medium">
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('why m1', {type: 'customers'})"
                            @click="$emit('close', false)"
                        >
                            <p class="group-hover:text-white">WHY M1 FOR CUSTOMERS</p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('product offerings', { type: 'customers' })"
                            @click="$emit('close', false)"
                        >
                            <p class="group-hover:text-white">PRODUCT OFFERINGS</p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('industries', { type: 'customers' })"
                            @click="$emit('close', false)"
                        >
                            <p class="group-hover:text-white">INDUSTRIES</p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                    <li
                        class="hover:bg-red-primary group rounded-md"
                    >
                        <Link
                            class="flex items-center gap-4 hover:gap-14 duration-300 p-4"
                            :href="route('how it works', { type: 'customers' })"
                            @click="$emit('close', false)"
                        >
                            <p class="group-hover:text-white">HOW IT WORKS FOR CUSTOMERS</p>
                            <ChevronRightIcon class="w-4 group-hover:text-white" />
                        </Link>
                    </li>
                </ul>
                <div class="p-4 w-full mt-6">
                    <Link
                        class="w-full block text-center text-white font-bold bg-red-primary px-4 py-2 rounded-lg"
                        :href="route('simple application')"
                        @click="$emit('close', false)"
                        >APPLY NOW</Link
                    >
                </div>
            </div>
        </div>
    </BackDrop>
</template>
